import React from 'react';
import '../styles/projectsStyle.css';

// Import each image
import munchMapImg from '../assets/projects/munchMap.png';
import bubbleTalkImg from '../assets/projects/bubbleTalk.png';
import ondoImg from '../assets/projects/ondo.png';
import wayImg from '../assets/projects/way.png';
import kakuNomiImg from '../assets/projects/kakunomi.png';
import chadaImg from '../assets/projects/chada.png';
import ursaworksImg from '../assets/projects/ursaworks.png';
import downTurnArrowIcon from '../assets/projects/icon/down-turn-arrow.svg';

const projectData = [
  {
    name: 'Munch Map',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Mobile App',
    image: munchMapImg,
    preview: true,
    src: 'https://jeffreyw2021.github.io/portfolio-pdf-store/munchmap.pdf',
  },
  {
    name: 'Bubble Talk',
    services: 'UI/UX Design',
    category: 'AR App',
    image: bubbleTalkImg,
    preview: true,
    src: 'https://jeffreyw2021.github.io/bubbleTalk/',
  },
  {
    name: 'Ondō',
    services: 'UI/UX Design',
    category: 'Mobile App',
    image: ondoImg,
    preview: true,
    src: 'https://jeffreyw2021.github.io/portfolio-pdf-store/ondo.pdf',
  },
  {
    name: 'WAY',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Mobile App',
    image: wayImg,
    preview: false,
    src: '',
  },
  {
    name: 'Ursaworks',
    services: 'UI/UX Design, Prototype Dev',
    category: 'Website',
    image: ursaworksImg,
    preview: false,
    src: '',
  },
  {
    name: 'Kaku Nomi',
    services: 'UI/UX Design',
    category: 'Mobile App',
    image: kakuNomiImg,
    preview: false,
    src: '',
  },
  {
    name: 'Chada',
    services: 'UI/UX Design, UX Research',
    category: 'Website',
    image: chadaImg,
    preview: false,
    src: '',
  },
];

export default function Projects({ onSelectProject }) {
  return (
    <div id="projects">
      <div className="projects-header">
        <h2>Projects.</h2>
        <img src={downTurnArrowIcon} alt="Scroll Down" />
      </div>
      <div className="project-infos"></div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Services</th>
            <th>Category</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projectData.map((project, index) => (
            <tr key={index}>
              <td>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (project.preview) {
                      onSelectProject(project);
                    }
                  }}
                >
                  {project.name}
                </a>
              </td>
              <td>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (project.preview) {
                      onSelectProject(project);
                    }
                  }}
                >{project.services}</a>
              </td>
              <td>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (project.preview) {
                      onSelectProject(project);
                    }
                  }}
                >{project.category}</a>
              </td>
              <td>
                {project.image && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (project.preview) {
                        onSelectProject(project);
                      }
                    }}
                  >
                    <img src={project.image} alt={project.name} />
                  </a>
                )}
              </td>
              <td className={project.preview ? 'preview' : ''}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (project.preview) {
                      onSelectProject(project);
                    }
                  }}
                >
                  <p>{project.preview ? 'detail for preview' : 'contact for detail'}</p>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
