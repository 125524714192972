import React, { useState, useEffect, useRef } from 'react';
import '../styles/dashboardStyle.css';
import Hero from '../components/hero';
import About from '../components/about';
import Projects from '../components/projects';
import References from '../components/references';
import Contact from '../components/contact';
import Detail from './Detail';
import Navbar from "../components/navbar";

export default function Dashboard() {
  const [showContact, setShowContact] = useState(true);
  const [showScrollDown, setShowScrollDown] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null); 
  const containerRef = useRef(null);
  const aboutRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const container = containerRef.current;
  //     if (!container) return;

  //     const totalHeight = container.scrollHeight;
  //     const scrollPosition = container.scrollTop + container.clientHeight;

  //     // if (scrollPosition >= totalHeight / 2) {
  //     //   setShowContact(true);
  //     // } else {
  //     //   setShowContact(false);
  //     // }
  //   };

  //   const container = containerRef.current;
  //   container.addEventListener('scroll', handleScroll);

  //   return () => {
  //     container.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowScrollDown(entry.intersectionRatio < 0.4); // Show button if less than 40% in view
      },
      {
        threshold: [0.4], // Trigger when 40% of About is in view
      }
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [heroLoaded, setHeroLoaded] = useState(false);

  useEffect(() => {
    if (heroLoaded) {
      setIsLoaded(true);
    }
  }, [heroLoaded]);

  const [year, setYear] = useState(2024);

  useEffect(() => {
    try {
      const currentYear = new Date().getFullYear();
      setYear(currentYear);
    } catch {
      setYear(2024);
    }
  }, []);

  return (
    <div className="container" ref={containerRef}>
      {!isLoaded && (
        <div className="loader-container">
          <div className="loaderball"></div>
        </div>
      )}
      <Navbar />
      <div className="scroller">
        <div className="scrollings">
          <Hero onLoaded={() => setHeroLoaded(true)} showScrollDown={showScrollDown} />
          <div ref={aboutRef}>
            <About />
          </div>
          <Projects onSelectProject={setSelectedProject} />
          <References />
          <Contact />
          <div className="footer">
            <p>&copy; {year} Z.Jeffrey Wang | All Rights Reserved</p>
          </div>
        </div>
      </div>
      {selectedProject && (
        <Detail
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
}
