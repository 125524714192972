import React from "react";
import Dashboard from "../screens/Dashboard";
import "../styles/globalControllerStyle.css"; 

export default function GlobalController() {

  return (
    <Dashboard />
  );
}
