import React, { useState, useEffect } from 'react';
import '../styles/detailStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export default function Detail({ project, onClose }) {
    const [iframeKey, setIframeKey] = useState(Date.now());

    useEffect(() => {
        let resizeTimeout;

        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                setIframeKey(Date.now());
            }, 300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generateIframeSrc = (src) => {
        if (!src || src.trim() === '') {
            return null;
        }

        const baseUrl = src.split('#')[0];
        const params = new URLSearchParams({
            view: 'FitH',
            toolbar: '0',
            statusbar: '0',
            messages: '0',
            navpanes: '0',
            scrollbar: '0',
        });

        return `${baseUrl}#${params.toString()}`;
    };

    const iframeSrc = generateIframeSrc(project.src);

    return (
        <div className="detail-overlay">
            <div className="detail-container">
                <div className="detail-header">
                    <h1>{project.name}.</h1>
                    <button onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} className="xIcon" />
                    </button>
                </div>
                {iframeSrc && (
                    <iframe
                        key={iframeKey}
                        src={iframeSrc}
                        title={`${project.name}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                            overflow: 'hidden'
                        }}
                    ></iframe>
                )}
            </div>
        </div>
    );
}
