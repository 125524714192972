import React, { useEffect } from 'react';
import '../styles/contactStyle.css';
import Navbar from './navbar';

// Import images
import mapBg from '../assets/contact/map-bg.png';
import arrowTopRight from '../assets/contact/arrow-top-right.svg';
import arrowTopRightWhite from '../assets/contact/arrow-top-right-white.svg';

export default function Contact({ onLoaded }) {
  useEffect(() => {
    const img = new Image();
    img.src = mapBg;
    img.onload = onLoaded;
  }, [onLoaded]);

  return (
    <div id="contact">
      <Navbar placeholder={true} />
      <div className="contact-body">
        <div className="relocation-container">
          <img src={mapBg} alt="Map background" />
          <div className="relocation-text">
            <p>Currently based in St.Louis, MO,</p>
            <h4>Jeffrey is open to <span className="highlight">relocation</span> and <span className="highlight">remote positions.</span></h4>
          </div>
        </div>

        <div className="contact-info">
          <h3>Grab a Coffee?</h3>
          <div className="contact-buttons">
            <a href="https://www.linkedin.com/in/ziqianwang2021/" title="Link to LinkedIn">
              <img src={arrowTopRight} alt="LinkedIn icon" />
              <img src={arrowTopRightWhite} alt="LinkedIn icon hover" />
              <p>LinkedIn</p>
            </a>
            <a href="https://www.behance.net/ziqianwang4" title="Link to Behance">
              <img src={arrowTopRight} alt="Behance icon" />
              <img src={arrowTopRightWhite} alt="Behance icon hover" />
              <p>Behance</p>
            </a>
            <a href="mailto:ziqianwang.jobsearch@gmail.com" title="Email Ziqian">
              <img src={arrowTopRight} alt="Email icon" />
              <img src={arrowTopRightWhite} alt="Email icon hover" />
              <p>Email</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
